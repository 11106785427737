<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col>
          <ion-card color="primary">
            <ion-card-header>
              <ion-card-subtitle> Questions added </ion-card-subtitle>
              <ion-card-title v-if="exam_subject">
                {{ exam_subject.exam_questions.length }}</ion-card-title
              >
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col>
          <ion-card color="primary">
            <ion-card-header>
              <ion-card-subtitle> Marks Assigned </ion-card-subtitle>
              <ion-card-title v-if="exam_subject">
                {{ assigned_marks }} of {{ full_mark }}
              </ion-card-title>
            </ion-card-header>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" class="ion-padding"
          ><ion-button
            expand="block"
            color="success"
            @click="getQuestionPaper()"
            >Download</ion-button
          ></ion-col
        >
      </ion-row>
    </ion-grid>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-question
            v-if="exam_subject"
            :resources="resources"
            :exam_subject="exam_subject"
          ></list-question>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button
        @click="setCreateModalState(true)"
        :disabled="
          this.exam_subject_state != 'Created' || assigned_marks >= full_mark
        "
        :color="
          this.exam_subject_state != 'Created' || assigned_marks >= full_mark
            ? 'dark'
            : 'primary'
        "
      >
        <ion-icon :ios="addOutline" :md="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-modal
      :is-open="createModalState"
      @didDismiss="setCreateModalState(false)"
    >
      <base-modal
        title="Add Exam Subject"
        @closeModal="setCreateModalState(false)"
      >
        <create-question
          @closeModal="setCreateModalState(false)"
          :exam_subject_id="exam_subject_id"
        ></create-question>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import BaseModal from "../../Base/BaseModal";
import CreateQuestion from "../../Components/Exam/ExamQuestion/CreateQuestion.vue";
import ListQuestion from "../../Components/Exam/ExamQuestion/ListQuestion.vue";
import base_url from "../../../apis/base_url";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonIcon,
  IonFabButton,
  IonButton,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/vue";

import { addOutline } from "ionicons/icons";

export default {
  props: ["exam_subject_id", "title"],

  components: {
    BaseLayout,
    BaseModal,
    CreateQuestion,
    ListQuestion,
    IonGrid,
    IonRow,
    IonCol,
    IonFab,
    IonIcon,
    IonFabButton,
    IonButton,
    IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
  },

  data() {
    return {
      backLink: "exam.list",
      reloadable: true,

      createModalState: false,

      addOutline,

      base_url,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["examQuestion/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
    assigned_marks() {
      if (!this.exam_subject) {
        return false;
      }
      let questions = this.exam_subject.exam_questions;
      let assigned_marks = 0;
      questions.forEach((question) => {
        assigned_marks += parseFloat(question.marks);
      });
      return assigned_marks;
    },
    exam_subject() {
      return this.$store.getters["examSubject/specificResource"];
    },
    exam_subject_state() {
      let exam_subject = this.exam_subject;
      return exam_subject ? exam_subject.exam_subject_state.name : "";
    },
    full_mark() {
      return this.exam_subject ? this.exam_subject.full_mark : 0;
    },
    token() {
      return this.$store.getters["auth/token"];
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("examQuestion/resources", {
        refresh: refresh,
        resourceId: this.exam_subject_id,
      });
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("examQuestion/resources", {
        refresh: true,
        resourceId: this.exam_subject_id,
      });
      if (event) {
        event.target.complete();
      }
    },
    setCreateModalState(state) {
      this.createModalState = state;
    },
    async fetchExamSubject() {
      try {
        await this.$store.dispatch(
          "examSubject/specificResource",
          this.exam_subject_id
        );
      } catch (error) {
        console.log(error);
      }
    },

    async getQuestionPaper() {
      const exam_subject_id = this.exam_subject_id;
      const url = `${this.base_url}api/v1/question-paper-print/${exam_subject_id}?pat=${this.token}`;
      const new_download_button = document.createElement("a");
      new_download_button.href = url;
      new_download_button.download = "question_paper.pdf";
      new_download_button.click();
    },
  },

  created() {
    this.fetch();
    this.fetchExamSubject();
  },
};
</script>

<style scoped></style>
